<template>
  <div class="church-profile template-3">
    <header class="page-header container pt-4">
      <h1>{{ translations.tcManageCalendars }}</h1>
    </header>
    <div v-for="(cal, id) in calendarDisplayArray" :key="id">
      <PrayerCalendar
        :name="cal.name"
        :items="cal.items"
        :level="cal.level"
        :i18n="translations.components"
        @delete_approved="deleteItem($event)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CardItem from '@/components/CardItem.vue'
import constantData from '@/json/data.json'
import PrayerCalendar from '@/components/PrayerCalendar.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-add-edit-calendar',
  mixins: [translationMixin, securityMixin],
  components: { CardItem, PrayerCalendar },
  data: () => ({
    translations: {},
    calendarDisplayArray: [],
    campLevel: 'camp',
    countryLevel: 'country',
    stateLevel: 'state',
    international: 'headquarters',
    secured_controls: {
      country_prayer_calendar_manage_panel: '3998e64e-43b9-480f-ba15-877708bd33ae',
      camp_prayer_calendar_manage_panel: 'edf13799-d1b8-4cea-9f40-4d13c463ff97',
      state_prayer_calendar_manage_panel: 'db8a4765-414e-4d14-b78a-2752455cbacf',
      hq_prayer_calendar_manage_panel: 'cbc03716-e57d-4e87-879c-71a84935b3a2',
    },
    country_panel_accessible: false,
    camp_panel_accessible: false,
    state_panel_accessible: false,
    hq_panel_accessible: false,
  }),
  methods: {
    ...mapActions({
      deleteCalendar: 'prayerCenter/deletePrayerCalendar',
      getCampPrayerListing: 'prayerCenter/getCampPrayerListing',
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      getCountryPrayerListing: 'prayerCenter/getCountryPrayerListing',
      getInternationalPrayerListing: 'prayerCenter/getInternationalPrayerListing',
      getStatePrayerListing: 'prayerCenter/getStatePrayerListing',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async pageLoad() {
      try {
        await Promise.all([await this.checkPermissions(), await this.performAPICalls()])
      } catch (e) {
        console.error(e)
      }
    },
    async performAPICalls() {
      await this.secured_hq_api_call()
      await this.secured_state_api_call()
      await this.secured_country_api_call()
      await this.secured_camp_api_call()
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.country_panel_accessible = this.determineAccessibility(
          this.secured_controls.country_prayer_calendar_manage_panel
        )
        this.camp_panel_accessible = this.determineAccessibility(
          this.secured_controls.camp_prayer_calendar_manage_panel
        )
        this.state_panel_accessible = this.determineAccessibility(
          this.secured_controls.state_prayer_calendar_manage_panel
        )
        this.hq_panel_accessible =
          this.determineAccessibility(this.secured_controls.hq_prayer_calendar_manage_panel) ||
          this.userLogin.campnumber === 'U50000'
      })
    },
    getOrgKey(level) {
      return (
        { camp: this.campKey, country: this.stateKey, state: this.stateKey, headquarters: this.internationalKey }[
          level
        ] || ''
      )
    },
    async secured_country_api_call() {
      if (this.country_panel_accessible) {
        if (this.userCountryKey !== constantData.USA_country) {
          await this.getCountryPrayerListing('editable')
          this.calendarDisplayArray.push({
            name: this.translations.tcCountryPrayerCalendar,
            items: this.countryCalendarValues,
            level: this.countryLevel,
          })
        }
      }
    },
    async secured_hq_api_call() {
      if (this.hq_panel_accessible) {
        await this.getInternationalPrayerListing('editable')
        this.calendarDisplayArray.push({
          name: this.translations.tcHeadquarters || 'Headquarters Prayer Calendar',
          items: this.internationalPrayerListing.calendars,
          level: this.international,
        })
      }
    },
    async secured_state_api_call() {
      if (this.state_panel_accessible) {
        if (this.userCountryKey === constantData.USA_country) {
          await this.getStatePrayerListing('editable')
          this.calendarDisplayArray.push({
            name: this.translations.tcStatePrayerCalendar || 'State Prayer Calendar',
            items: this.stateCalendarValues,
            level: this.stateLevel,
          })
        }
      }
    },
    async secured_camp_api_call() {
      if (this.camp_panel_accessible) {
        await this.getCampPrayerListing('editable')
        this.calendarDisplayArray.push({
          name: this.translations.tcCampPrayerCalendar || 'Camp Prayer Calendar',
          items: this.campCalendarValues,
          level: this.campLevel,
        })
      }
    },
    async deleteItem(item) {
      try {
        this.setLoadingStatus(true)
        let params = {
          orgKey: this.getOrgKey(item.level),
          key: item.key,
        }
        const response = await this.deleteCalendar(params)
        if (response) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcCalendarDeletedSuccessfully,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then(() => {
            this.calendarDisplayArray.splice(0)
            this.pageLoad()
          })
        } else {
          this.$swal({
            icon: 'error',
            text: this.translations.tcErrorDeletingCalendar,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('prayer-calendar'),
        await this.pageLoad(),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      campKey: 'user/userCampKey',
      campPrayerListing: 'prayerCenter/campPrayerListing',
      countryPrayerListing: 'prayerCenter/countryPrayerListing',
      internationalKey: 'user/internationalKey',
      internationalPrayerListing: 'prayerCenter/internationalPrayerListing',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      stateKey: 'user/userStateKey',
      statePrayerListing: 'prayerCenter/statePrayerListing',
      userCountryKey: 'user/userCountryKey',
      userLogin: 'user/userLogin',
    }),
    campCalendarValues() {
      return !!this.campPrayerListing.calendars
        ? this.campPrayerListing.calendars.filter((calendar) => calendar.type != 'Special')
        : []
    },
    stateCalendarValues() {
      return !!this.statePrayerListing.calendars
        ? this.statePrayerListing.calendars.filter((calendar) => calendar.type != 'Special')
        : []
    },
    countryCalendarValues() {
      return !!this.countryPrayerListing.calendars
        ? this.countryPrayerListing.calendars.filter((calendar) => calendar.type != 'Special')
        : []
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/bootstrap/b-button.scss';
</style>
